import React, { Component } from "react";
import { Button, Col, Layout, Menu, Row, Space } from "antd";
import {
  ApartmentOutlined,
  BookOutlined,
  ToolOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Outlet } from "react-router-dom";
import { ItemType } from "antd/es/menu/hooks/useItems";
import withRouter, { WithRouterProps } from "../../hoc/withRouter";
import "./Admin.scss";
import logo from "../../assets/logo.svg";
import { CurrentUserComponent } from "../../components/current-user/CurrentUserComponent";
import Breadcrumbs from "../../components/Breadcrumbs";
import PageContent from "../../components/PageContent";

const { Sider, Content, Header } = Layout;

type State = {
  collapsed: boolean;
  menu: ItemType[];
  selectedMenuItemKey: string | undefined;
  menuBreakpointBroken: boolean;
};

export const ADMIN_ROUTE_PREFIX = "admin";

class Admin extends Component<WithRouterProps, State> {
  state = {
    collapsed: false,
    selectedMenuItemKey: undefined,
    menuBreakpointBroken: false,
    menu: [
      {
        label: "Zamówienia",
        key: "orders",
        onClick: (e) =>
          this.setMenuItemAndRedirect(e.key, `/${ADMIN_ROUTE_PREFIX}/orders`),
        icon: <UnorderedListOutlined />,
      },
      {
        label: "Przepisy",
        key: "recipes",
        onClick: (e) =>
          this.setMenuItemAndRedirect(e.key, `/${ADMIN_ROUTE_PREFIX}/recipes`),
        icon: <BookOutlined />,
      },
      {
        label: "Produkty",
        key: "products",
        onClick: (e) =>
          this.setMenuItemAndRedirect(e.key, `/${ADMIN_ROUTE_PREFIX}/products`),
        icon: <ApartmentOutlined />,
      },
      {
        label: "Narzędzia",
        key: "tools",
        icon: <ToolOutlined />,
        children: [
          {
            label: "Kalendarz",
            key: "calendar",
            onClick: (e) =>
              this.setMenuItemAndRedirect(
                e.key,
                `/${ADMIN_ROUTE_PREFIX}/calendar`
              ),
            icon: <CalendarOutlined />,
          },
        ],
      },
    ] as ItemType[],
  };

  componentDidMount = () => {
    this.selectMenuItemOnLoad();
  };

  componentDidUpdate(prevProps: Readonly<WithRouterProps>): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.selectMenuItemOnLoad();
    }
  }

  private selectMenuItemOnLoad = () => {
    const currentUrl = this.props.location.pathname;
    const currentMenu = this.state.menu.find(
      (x) =>
        x?.key &&
        currentUrl.startsWith(`/${ADMIN_ROUTE_PREFIX}/${x.key.toString()}`)
    );
    const currentMenuKey = currentMenu?.key?.toString();
    if (currentMenuKey) {
      this.selectMenuItem(currentMenuKey);
    }
  };

  private selectMenuItem = (key: string) => [
    this.setState({
      selectedMenuItemKey: key,
    }),
  ];

  private setBreakepointBroken = (broken: boolean) => {
    this.setState({
      menuBreakpointBroken: broken,
    });
  };

  private collapseMenuForMobile = () => {
    if (this.state.menuBreakpointBroken) {
      this.toggle(true);
    }
  };

  toggle = (collapse: boolean | null = null) => {
    this.setState({
      collapsed: collapse !== null ? collapse : !this.state.collapsed,
    });
  };

  setMenuItemAndRedirect = (key: string, href: string) => {
    this.selectMenuItem(key);
    this.props.navigate(href);
  };

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          breakpoint="lg"
          collapsed={this.state.collapsed}
          onBreakpoint={(broken) => this.setBreakepointBroken(broken)}
          onCollapse={this.toggle}
        >
          <div className="logo">
            <img src={logo} alt="Cake" />
            {!this.state.collapsed && "Piekę Bo Lubię!"}
          </div>
          <Menu
            theme="dark"
            selectedKeys={
              this.state.selectedMenuItemKey
                ? [this.state.selectedMenuItemKey]
                : []
            }
            onSelect={() => this.collapseMenuForMobile()}
            mode="inline"
            items={this.state.menu}
          />
        </Sider>
        <Layout className="site-layout">
          <Header style={{ padding: 0, background: "white" }}>
            <Row wrap={false}>
              <Col>
                <Button
                  className="toggle-button"
                  type="text"
                  icon={
                    this.state.collapsed ? (
                      <MenuUnfoldOutlined />
                    ) : (
                      <MenuFoldOutlined />
                    )
                  }
                  onClick={() => this.toggle()}
                />
              </Col>
              <Col className="current-user-wrapper">
                <Space className="current-user">
                  <CurrentUserComponent />
                </Space>
              </Col>
            </Row>
          </Header>
          <Content className="site-layout-background">
            <PageContent transparent={true} paddingY={0}>
              <Breadcrumbs />
            </PageContent>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(Admin);
