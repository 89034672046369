import React from "react";
import {
  Link,
  Navigate,
  Outlet,
  Params,
  createBrowserRouter,
} from "react-router-dom";
import Admin, { ADMIN_ROUTE_PREFIX } from "../modules/admin/Admin";
import ProtectedRoute from "../components/ProtectedRoute";
import {
  HomeOutlined,
  ApartmentOutlined,
  BookOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Suspense, lazy } from "react";
import Auth from "../modules/auth/Auth";
import Loader from "../components/Loader";

const Login = lazy(() => import("../modules/auth/login/Login"));

const CalendarPage = lazy(
  () => import("../modules/admin/calendar/CalendarPage")
);
const Recipes = lazy(() => import("../modules/admin/recipes/Recipes"));
const UpdateRecipe = lazy(
  () => import("../modules/admin/recipes/update-recipe/UpdateRecipe")
);
const Orders = lazy(() => import("../modules/admin/orders/Orders"));
const UpdateOrder = lazy(
  () => import("../modules/admin/orders/update-order/UpdateOrder")
);
const Products = lazy(() => import("../modules/admin/products/Products"));
const UpdateProduct = lazy(
  () => import("../modules/admin/products/update-product/UpdateProduct")
);

const appRouter = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "",
        element: <Navigate to="/auth/login" />,
      },
      {
        path: "login",
        element: (
          <Suspense fallback={<Loader isLoading={true} />}>
            <Login />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: `/${ADMIN_ROUTE_PREFIX}`,
    element: (
      <ProtectedRoute>
        <Admin />
      </ProtectedRoute>
    ),
    handle: {
      crumb: () => (
        <Link to="/admin">
          <HomeOutlined />
        </Link>
      ),
    },
    children: [
      {
        path: "",
        element: <Navigate to="/admin/orders" />,
      },
      {
        path: "calendar",
        element: (
          <Suspense fallback={<Loader isLoading={true} />}>
            <CalendarPage />
          </Suspense>
        ),
        handle: {
          crumb: () => (
            <Link to="/admin/calendar">
              <CalendarOutlined /> Kalendarz
            </Link>
          ),
        },
      },
      {
        path: `products`,
        element: <Outlet />,
        handle: {
          crumb: () => (
            <Link to="/admin/products">
              <ApartmentOutlined /> Produkty
            </Link>
          ),
        },
        children: [
          {
            path: ``,
            element: (
              <Suspense fallback={<Loader isLoading={true} />}>
                <Products />
              </Suspense>
            ),
          },
          {
            path: `:id`,
            element: (
              <Suspense fallback={<Loader isLoading={true} />}>
                <UpdateProduct />
              </Suspense>
            ),
            handle: {
              crumb: (params: Params<string>) => (
                <Link to={`/admin/products/${params.id}`}>Edycja</Link>
              ),
            },
          },
        ],
      },
      {
        path: `orders`,
        element: <Outlet />,
        handle: {
          crumb: () => (
            <Link to="/admin/orders">
              <UnorderedListOutlined /> Zamówienia
            </Link>
          ),
        },
        children: [
          {
            path: ``,
            element: (
              <Suspense fallback={<Loader isLoading={true} />}>
                <Orders />
              </Suspense>
            ),
          },
          {
            path: `:id`,
            element: (
              <Suspense fallback={<Loader isLoading={true} />}>
                <UpdateOrder />
              </Suspense>
            ),
            handle: {
              crumb: (params: Params<string>) => (
                <Link to={`/admin/orders/${params.id}`}>Edycja</Link>
              ),
            },
          },
        ],
      },
      {
        path: `recipes`,
        element: <Outlet />,
        handle: {
          crumb: () => (
            <Link to="/admin/recipes">
              <BookOutlined /> Przepisy
            </Link>
          ),
        },
        children: [
          {
            path: ``,
            element: (
              <Suspense fallback={<Loader isLoading={true} />}>
                <Recipes />
              </Suspense>
            ),
          },
          {
            path: `:id`,
            element: (
              <Suspense fallback={<Loader isLoading={true} />}>
                <UpdateRecipe />
              </Suspense>
            ),
            handle: {
              crumb: (params: Params<string>) => (
                <Link to={`/admin/recipes/${params.id}`}>Edycja</Link>
              ),
            },
          },
        ],
      },
    ],
  },
  { path: "*", element: <Navigate to={`/${ADMIN_ROUTE_PREFIX}`} replace /> },
]);

export default appRouter;
