import React from "react";
import { Avatar, Dropdown, MenuProps, Space } from "antd";
import { UserOutlined, LogoutOutlined, DownOutlined } from "@ant-design/icons";
import { CurrentUserNameComponent } from "./CurrentUserNameComponent";
import authenticationService from "../../services/AuthenticationService";
import { useNavigate } from "react-router-dom";
import "./CurrentUserComponent.scss";

export const CurrentUserComponent = () => {
  const navigate = useNavigate();
  const logout = () => {
    authenticationService.logout();
    navigate("/auth");
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <>
          <Space>
            <LogoutOutlined />
            Logout
          </Space>
        </>
      ),
      onClick: logout,
      key: "logout",
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Space className="current-user">
          <Avatar
            style={{ backgroundColor: "#87d068" }}
            icon={<UserOutlined />}
          />
          <CurrentUserNameComponent />
          <DownOutlined />
        </Space>
      </Dropdown>
    </>
  );
};
