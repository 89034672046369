export default class CurrentUserResponse {
    id: string;
    email: string;
    roles: string[];

    constructor(token: { nameid: string, email: string, roles: string }) {
        this.id = token.nameid;
        this.email = token.email;
        this.roles = token.roles?.split(',');
    }
}