import dayjs from "dayjs";
import locale from "antd/es/locale/pl_PL";
import "dayjs/plugin/updateLocale";
import "dayjs/locale/pl";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);

dayjs.updateLocale("pl", {
    weekStart: 1,
});

export default locale;