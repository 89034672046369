import React from "react";
import "./Auth.scss";
import { Outlet } from "react-router-dom";

import { Layout } from "antd";

const { Content } = Layout;

function Auth() {
  return (
    <Layout className="layout auth-layout">
      <Content style={{ padding: "0 50px" }}>
        <Outlet />
      </Content>
    </Layout>
  );
}

export default Auth;
