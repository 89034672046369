import http from './common/apiClient';
import LoginRequest from '../models/authentication/LoginRequest';
import jwt_decode from 'jwt-decode';
import CurrentUserResponse from '../models/authentication/CurrentUserResponse';

const AUTH_TOKEN: string = 'AUTH_TOKEN';

const storeToken = (token: string) => {
    localStorage.setItem(AUTH_TOKEN, token);
}

const removeToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
}

const getToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
}

const authenticationService = {
    login: async (model: LoginRequest) => {
        const { data: jwt } = await http.post(`/authentication/login`, model);
        storeToken(jwt);
        return true;
    },
    logout: () => {
        removeToken();
    },
    getToken: () => {
        return getToken();
    },
    getCurrentUser: () => {
        const token = getToken();
        if (token) {
            return new CurrentUserResponse(jwt_decode<{ nameid: string, email: string, roles: string }>(token));
        }
        return null;
    },
    isLoggedIn: (): boolean => {
        return authenticationService.getCurrentUser() !== null;
    },
    isInRole: (role: string): boolean => {
        const currentUser = authenticationService.getCurrentUser();
        if (currentUser !== null) {
            return currentUser.roles.indexOf(role) > -1;
        }
        return false;
    }
}

export default authenticationService;
