import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const env = process.env.REACT_APP_ENVIRONMENT;
const sentry = process.env.REACT_APP_SENTRY_DSN;

const init = () => {
    Sentry.init({
        environment: env,
        dsn: sentry,
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
};

const log = (error: object) => {
    Sentry.captureEvent(error);
}

const logger = {
    init: init,
    log: log
}

export default logger;