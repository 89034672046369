import React from "react";
import { Spin } from "antd";
import { ComponentType } from "react";

type Props = {
  isLoading: boolean;
  hasError?: boolean;
  children?: React.ReactNode;
  errorComponent?: ComponentType;
};

const Loader = ({
  isLoading,
  hasError,
  errorComponent: ErrorComponent,
  children,
}: Props) => {
  if (isLoading) {
    return (
      <Spin style={{ width: "100%" }} spinning={isLoading}>
        {children}
      </Spin>
    );
  } else if (hasError && ErrorComponent) {
    return <ErrorComponent />;
  } else if (children) {
    return <>{children}</>;
  }
  return <></>;
};

export default Loader;
