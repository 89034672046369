import React from "react";
import "./App.scss";
import appRouter from "./utils/appRouter";
import { RouterProvider } from "react-router-dom";

function App() {
  return <RouterProvider router={appRouter} />;
}

export default App;
