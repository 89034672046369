import axios, { AxiosError, AxiosRequestConfig } from "axios";
import logger from "../LogService";
import { message } from 'antd';
import authenticationService from "../AuthenticationService";
import { AxiosResponse } from "axios";
import appRouter from "../../utils/appRouter";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const apiClient = axios.create({
    baseURL: API_URL,
    timeout: 10000
});

const requestHandler = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = authenticationService.getToken();
    if (token !== null && config.headers) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config;
}

const responseHandler = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
    return Promise.resolve(response);
}

const errorHandler = (error: AxiosError) => {
    const expectedError =
        error.response &&
        error.response?.status >= 400 &&
        error.response?.status < 500;

    if (error.response?.statusText === 'Network Error' || error?.code === 'ERR_NETWORK') {
        message.error('Wystąpił błąd z siecią, spróbuj ponownie!')
    } else if (!expectedError) {
        message.error('Wystąpił nieoczekiwany błąd!')
    } else if (error.response?.status === 401) {
        authenticationService.logout();
        appRouter.navigate('/auth/login');
    }

    logger.log(error);
    return Promise.reject(error);
};

apiClient.interceptors.request.use(
    (config) => requestHandler(config),
    (error) => errorHandler(error)
);

apiClient.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
)

export default apiClient;