import React from "react";
import { Content } from "antd/es/layout/layout";

type Props = {
  children: React.ReactElement[] | React.ReactElement;
  transparent?: boolean;
  paddingY?: number;
  paddingX?: number;
  marginY?: number;
  marginX?: number;
};

const PageContent = ({
  children,
  transparent = false,
  paddingY = 12,
  paddingX = 12,
  marginY = 16,
  marginX = 16,
}: Props) => {
  return (
    <Content
      style={{
        marginTop: `${marginY}px`,
        marginBottom: `${marginY}px`,
        marginLeft: `${marginX}px`,
        marginRight: `${marginX}px`,

        paddingTop: `${paddingY}px`,
        paddingBottom: `${paddingY}px`,
        paddingLeft: `${paddingX}px`,
        paddingRight: `${paddingX}px`,

        backgroundColor: transparent ? "transparent" : "white",
      }}
    >
      {children}
    </Content>
  );
};

export default PageContent;
