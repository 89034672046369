import React from "react";
import { Breadcrumb } from "antd";
import { Params, useMatches } from "react-router-dom";

type Match = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: {
    crumb: (params: Params<string>) => JSX.Element;
  };
};

const Breadcrumbs = () => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean((match as Match).handle?.crumb))
    .map((match) => (match as Match).handle.crumb(match.params));

  return (
    <>
      <Breadcrumb
        items={crumbs.map((crumb) => {
          return {
            title: crumb,
          };
        })}
      />
    </>
  );
};

export default Breadcrumbs;
