import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import authenticationService from "../services/AuthenticationService";

type Props = {
  children: JSX.Element;
};

const ProtectedRoute = (props: Props) => {
  if (authenticationService.isLoggedIn()) {
    return props.children ? props.children : <Outlet />;
  } else {
    return <Navigate to="/auth" />;
  }
};

export default ProtectedRoute;
